import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Page from "../components/Page";
import Card from "../components/Card";
import Container from "../components/Container";

const Tile = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

const Header = styled.header`
  padding: 15px;
  background-color: rgb(132, 86, 161);
`;

const PageTitle = styled.h1`
  margin-bottom: 0;
  color: white;
`;

const PageTemplate = ({ data }) => {
  const { markdownRemark: page } = data;
  return (
    <Page
      header={
        <Header>
          <Tile
            css={css`
              padding: 200px 0 30px;
            `}
          >
            <Container>
              <PageTitle>{page.frontmatter.title}</PageTitle>
            </Container>
          </Tile>
        </Header>
      }
      fixedHeader
    >
      <Helmet title={`Kranich Immobilien - ${page.frontmatter.title}`} />
      <Tile>
        <Card>
          <Container mt={40}>
            <div dangerouslySetInnerHTML={{ __html: page.html }} />
          </Container>
        </Card>
      </Tile>
    </Page>
  );
};

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default PageTemplate;
